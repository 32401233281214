html,body{
    font-family: $font-family-sans-serif;
    font-size: $font-size;
    line-height: $line-height-base;
    position: relative;
}



h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-sans-serif-titles;
    font-weight: 700;
    margin: 0;
    margin-bottom: 2rem;
}

h3, h4, h5, h6 {
    font-weight: 700;
}

h6 {
    font-weight: 600;
}

.h1, .h2, .h3, h1, h2, h3 {
    margin: 0;
    margin-bottom: 2rem;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.4rem;
}

h5 {
    font-size: 1.2rem;
}

h6 {
    font-size: 1rem;
}


