
.label {
    background-color: $light-gray;
    color: $label-badge-color;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
    border-radius: 0.25em;
    line-height: 1;
    white-space: nowrap;
}

.nav .label, .ibox .label {
    font-size: 10px;
}

.badge {
    background-color: $light-gray;
    color: $label-badge-color;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    text-shadow: none;
    white-space: nowrap;
}

.label-primary, .badge-primary {
    background-color: $blue;
    color: #FFFFFF;
}

.label-primary-light, .badge-primary-light {
    background-color: lighten($blue,5%);
    color: #FFFFFF;
}

.label-success, .badge-success {
    background-color: $green;
    color: #FFFFFF;
}


.label-success-light, .badge-success-light {
    background-color: lighten($green,5%);
    color: #FFFFFF;
}

.label-warning, .badge-warning {
    background-color: $yellow;
    color: #FFFFFF;
}

.label-warning-light, .badge-warning-light {
    background-color: lighten($yellow,5%);
    color: #ffffff;
}

.label-danger, .badge-danger {
    background-color: $red;
    color: #FFFFFF;
}


.label-danger-light, .badge-danger-light {
    background-color: lighten($red,5%);
    color: #FFFFFF;
}

.label-info, .badge-info {
     background-color: $lazur;
     color: #FFFFFF;
 }

.label-info-light, .badge-info-light {
    background-color: lighten($lazur,5%);
    color: #FFFFFF;
}


.label-purple, .badge-purple {
    background-color: $purple;
    color: #FFFFFF;
}

.label-purple-light, .badge-purple-light {
    background-color: lighten($purple,5%);
    color: #FFFFFF;
}


.label-orange, .badge-orange {
    background-color: $orange;
    color: #FFFFFF;
}

.label-orange-light, .badge-orange-light {
    background-color: lighten($orange,5%);
    color: #FFFFFF;
}

.label-teal, .badge-teal {
    background-color: $teal;
    color: #FFFFFF;
}

.label-teal-light, .badge-teal-light {
    background-color: lighten($teal,5%);
    color: #FFFFFF;
}








.label-inverse, .badge-inverse {
    background-color: #262626;
    color: #FFFFFF;
}

.label-white, .badge-white {
    background-color: #FFFFFF;
    color: #5E5E5E;
}

.label-white, .badge-disable {
    background-color: #2A2E36;
    color: #8B91A0;
}
