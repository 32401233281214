button:focus {
    outline: 0 !important;
}

.btn {
    border-radius: $btn-border-radius;
    font-size: 1rem;
    padding: 0.375rem 1.5rem;
    font-weight: 600;
    -webkit-transition: $trasition ;
    -moz-transition: $trasition ;
    -ms-transition: $trasition ;
    -o-transition: $trasition ;
    transition: $trasition ;
}


.btn:focus {
    box-shadow: none;
}

.btn-sm{
    font-size: 1rem;
    padding: 0.35rem 0.85rem;
}


.btn-xs {
    font-size: 0.8rem;
    padding: 0.2rem 0.4rem;
}

.btn-group-sm > .btn,
.btn-sm {
    font-size: 0.8rem;
    padding: 0.25rem 0.85rem;
}

.float-e-margins .btn {
    margin-bottom: 5px;
}

.btn-w-m {
    min-width: 120px;
}


.btn-icon{
    padding: 0;
    border: 0;
    text-align: center;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    -webkit-transition: $trasition;
    -moz-transition: $trasition ;
    -ms-transition: $trasition ;
    -o-transition: $trasition ;
    transition: $trasition ;
    margin: 0 0.1rem;
}

.btn-icon:hover{
    transform: scale(1.1);
}

.btn-primary.btn-outline {
    color: $navy;
}

.btn-success.btn-outline {
    color: $blue;
}

.btn-info.btn-outline {
    color: $lazur;
}

.btn-warning.btn-outline {
    color: $yellow;
}

.btn-danger.btn-outline {
    color: $red;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.btn.active, .btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-primary {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
    background-color: darken($blue, 6%);
    border-color: darken($blue, 6%);
    color: #FFFFFF;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: darken($blue, 6%);
    border-color: darken($blue, 6%);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: darken($blue, 6%);
    border-color: darken($blue, 6%);
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}




.btn-blue {
    color: #fff;
    background-color: $navy;
    border-color: $navy;
}

.btn-blue:hover, .btn-blue:focus, .btn-blue.focus {
    background-color: darken($navy, 6%);
    border-color: darken($navy, 6%);
    color: #FFFFFF;
}

.btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: darken($navy, 6%);
    border-color: darken($navy, 6%);
}

.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: darken($navy, 6%);
    border-color: darken($navy, 6%);
}

.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.btn-success {
    color: #fff;
    background-color: $green;
    border-color: $green;
}

.btn-success:hover, .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: darken($green, 6%);
    border-color: darken($green, 6%);
}

.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: darken($green, 6%);
    border-color: darken($green, 6%);
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: darken($green, 6%);
    border-color: darken($green, 6%);
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}



.btn-info {
    color: #fff;
    background-color: $lazur;
    border-color: $lazur;
}

.btn-info:hover, .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: darken($lazur, 6%);
    border-color: darken($lazur, 6%);
}

.btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: darken($lazur, 6%);
    border-color: darken($lazur, 6%);
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: darken($lazur, 6%);
    border-color: darken($lazur, 6%);
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.btn-default {
    color: inherit;
    background: white;
    border: 1px solid $border-color;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default, .btn-default:active:focus, .btn-default:active:hover, .btn-default.active:hover, .btn-default.active:focus {
    color: inherit;
    background-color: lighten($border-color, 15%);
    border: 1px solid darken($border-color, 10%);
}

.btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default.active[disabled], fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
    color: rgba($dark, 0.5);
}




.btn-warning {
    color: #ffffff;
    background-color: $yellow;
    border-color: $yellow;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning.focus {
    color: #ffffff;
    background-color: darken($yellow, 6%);
    border-color: darken($yellow, 3%);
}

.btn-warning.disabled, .btn-warning:disabled {
    color: #ffffff;
    background-color: darken($yellow, 6%);
    border-color: darken($yellow, 6%);
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: darken($yellow, 6%);
    border-color: darken($yellow, 6%);
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}



.btn-danger {
    color: #fff;
    background-color: $red;
    border-color: $red;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: darken($red, 6%);
    border-color: darken($red, 6%);
}

.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: darken($red, 6%);
    border-color: darken($red, 6%);
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: darken($red, 6%);
    border-color: darken($red, 6%);
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}



.btn-link {
    color:  $blue;
    font-weight: normal;
}

.btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active, .open .dropdown-toggle.btn-link {
    color: darken($blue, 5%);
    text-decoration: none;
}

.btn-link:active, .btn-link.active, .open .dropdown-toggle.btn-link {
    background-image: none;
    box-shadow: none;
}

.btn-link.disabled, .btn-link.disabled:hover,
.btn-link.disabled:focus, .btn-link.disabled:active,
.btn-link.disabled.active, .btn-link[disabled],
.btn-link[disabled]:hover, .btn-link[disabled]:focus,
.btn-link[disabled]:active, .btn-link.active[disabled],
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
    color: lighten($blue,20%);
}



.btn-white {
    color: inherit;
    background: white;
    border: 1px solid $border-color;
}

.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white, .btn-white:active:focus, .btn-white:active:hover, .btn-white.active:hover, .btn-white.active:focus {
    color: inherit;
    border: 1px solid #d2d2d2;
}

.btn-white:active, .btn-white.active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white {
    background-image: none;
}

.btn-white.disabled, .btn-white.disabled:hover, .btn-white.disabled:focus, .btn-white.disabled:active, .btn-white.disabled.active, .btn-white[disabled], .btn-white[disabled]:hover, .btn-white[disabled]:focus, .btn-white[disabled]:active, .btn-white.active[disabled], fieldset[disabled] .btn-white, fieldset[disabled] .btn-white:hover, fieldset[disabled] .btn-white:focus, fieldset[disabled] .btn-white:active, fieldset[disabled] .btn-white.active {
    color: #cacaca;
}



.btn-dark {
    color: #ffffff;
    background-color: $dark;
    border-color: $dark;
}

.btn-dark:hover, .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: darken($dark, 6%);
    border-color: darken($dark, 3%);
}

.btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: darken($dark, 6%);
    border-color: darken($dark, 6%);
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: darken($dark, 6%);
    border-color: darken($dark, 6%);
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}



.btn-pink {
    color: #ffffff;
    background-color: $pink;
    border-color: $pink;
}

.btn-pink:hover, .btn-pink:focus, .btn-pink.focus {
    color: #ffffff;
    background-color: darken($pink, 6%);
    border-color: darken($pink, 3%);
}

.btn-pink.disabled, .btn-pink:disabled {
    color: #ffffff;
    background-color: darken($pink, 6%);
    border-color: darken($pink, 6%);
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
    color: #ffffff;
    background-color: darken($pink, 6%);
    border-color: darken($pink, 6%);
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-purple {
    color: #ffffff;
    background-color: $purple;
    border-color: $purple;
}

.btn-purple:hover, .btn-purple:focus, .btn-purple.focus {
    color: #ffffff;
    background-color: darken($purple, 6%);
    border-color: darken($purple, 3%);
}

.btn-purple.disabled, .btn-purple:disabled {
    color: #ffffff;
    background-color: darken($purple, 6%);
    border-color: darken($purple, 6%);
}

.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
    color: #ffffff;
    background-color: darken($purple, 6%);
    border-color: darken($purple, 6%);
}

.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.btn-outline-primary {
    color: $blue;
    border-color: $blue;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: unset;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: $blue;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}


.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
    box-shadow: none;
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-rounded {
    border-radius: 50px;
}

.btn-large-dim {
    width: 90px;
    height: 90px;
    font-size: 42px;
}

button.dim {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    padding-top: 6px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    margin-bottom: 20px !important;
}

button.dim:active {
    top: 3px;
}

button.btn-primary.dim {
    box-shadow: inset 0 0 0 darken($navy, 6%), 0 5px 0 0 darken($navy, 6%), 0 10px 5px #999 !important;
}

button.btn-primary.dim:active {
    box-shadow: inset 0 0 0 darken($navy, 6%), 0 2px 0 0 darken($navy, 6%), 0 5px 3px #999 !important;
}

button.btn-default.dim {
    box-shadow: inset 0 0 0 darken($dark-gray, 6%), 0 5px 0 0 darken($dark-gray, 6%), 0 10px 5px #999 !important;
}

button.btn-default.dim:active {
    box-shadow: inset 0 0 0 darken($dark-gray, 6%), 0 2px 0 0 darken($dark-gray, 6%), 0 5px 3px #999 !important;
}

button.btn-warning.dim {
    box-shadow: inset 0 0 0 darken($yellow, 6%), 0 5px 0 0 darken($yellow, 6%), 0 10px 5px #999 !important;
}

button.btn-warning.dim:active {
    box-shadow: inset 0 0 0 darken($yellow, 6%), 0 2px 0 0 darken($yellow, 6%), 0 5px 3px #999 !important;
}

button.btn-info.dim {
    box-shadow: inset 0 0 0 darken($lazur, 6%), 0 5px 0 0 darken($lazur, 6%), 0 10px 5px #999 !important;
}

button.btn-info.dim:active {
    box-shadow: inset 0 0 0 darken($lazur, 6%), 0 2px 0 0 darken($lazur, 6%), 0 5px 3px #999 !important;
}

button.btn-success.dim {
    box-shadow: inset 0 0 0 darken($blue, 6%), 0 5px 0 0 darken($blue, 6%), 0 10px 5px #999 !important;
}

button.btn-success.dim:active {
    box-shadow: inset 0 0 0 darken($blue, 6%), 0 2px 0 0 darken($blue, 6%), 0 5px 3px #999 !important;
}

button.btn-danger.dim {
    box-shadow: inset 0 0 0 darken($red, 6%), 0 5px 0 0 darken($red, 6%), 0 10px 5px #999 !important;
}

button.btn-danger.dim:active {
    box-shadow: inset 0 0 0 darken($red, 6%), 0 2px 0 0 darken($red, 6%), 0 5px 3px #999 !important;
}

button.dim:before {
    font-size: 50px;
    line-height: 1em;
    font-weight: normal;
    color: #fff;
    display: block;
    padding-top: 10px;

}

button.dim:active:before {
    top: 7px;
    font-size: 50px;
}

.btn:focus {
    outline: none !important;
}
