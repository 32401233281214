// Basic Colors

$dark-gray: #c0c7c7; // Default color
$blue: #0072c6; // Success color
$lazur:  #0bb2d4; // Info color
$yellow: #faa700; // Warning color
$red: #ff4c52; // Danger color
$dark: #282d2d;
$green: #00d27f;
$pink: #f74584;
$purple: #9463f7;
$indigo: #667afa;
$teal: #17b3a3;
$navy:  #00467a; // Primary color




// Colors
//$blue: #3490dc;
//$indigo: #6574cd;
//$purple: #9561e2;
//$pink: #f66d9b;
//$red: #e3342f;
//$orange: #f6993f;
//$yellow: #ffed4a;
//$green: #38c172;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;


$top-nav-color-link: #2c303b;
$menu-bk-color-link: #e2e4ea;

// Various colors


$text-color: #282d2d; // Body text
$gray: #e8ebeb; // Background wrapper color
$light-gray: #cdd3d3; // Default label, badge
$label-badge-color: #5E5E5E;
$light-blue: #707e7e;
$font-family-sans-serif: 'Nunito', sans-serif;




//$font-family-sans-serif-titles: 'Montserrat', sans-serif;
$font-family-sans-serif-titles: $font-family-sans-serif;
$font-size: 16px;

$font-size-base: 0.9rem;
$line-height-base: 1.2;

// Spinner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

//Basics
$basic-link-color: $blue;

// IBOX colors ( default panel colors)
$border-color: lighten($dark,50%); // IBox border
$ibox-title-bg: #ffffff; // IBox Background header
$ibox-content-bg: #ffffff; // IBox Background content

//Sidebar width
$sidebar-width: 240px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: image-url('patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 5rem;
$border-radius: 0.15rem;

//Navigation
//$nav-bg: #2c303b;
$nav-bg: $navy;
$nav-profile-pattern: image-url("patterns/header-profile.png");
$nav-text-color: opacify(white,0.80);

$bg-pattern: url('../images/bk.svg');
$bg-pattern-auth_: url('../images/bk-3.svg');
$bg-pattern-auth: url('../images/bk-1.svg');
$bg-panel-login: url('../images/bk-panel-login.jpg');


$box-shadow: 0 1px 15px 1px rgba(62, 57, 107, .07);
$box-shadow-2: 0 10px 18px 0 rgba(62, 57, 107, .2);
$trasition: all ease-in-out 0.3s;
$transition: $trasition;
$radiusXl: 1rem;


