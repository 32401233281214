.login-panel {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);

    p{
        font-size: 0.8rem;
    }

    .form-group{
        margin-bottom: 1.4rem;
    }


    .form-group > label{
        display: none;
    }


    .input-group{
        .input-group-text{
            //border-top-left-radius: 5rem;
            //border-bottom-left-radius: 5rem;
            padding-right: 0;
        }
       .form-control{
           //border-top-right-radius: 5rem;
           //border-bottom-right-radius: 5rem;
       }
    }

    .bg-panel-login{
        width: 100%;
        height: 100%;
        position: relative;
        background-color: white;
        background-image: $bg-panel-login;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: auto 120%;
    }

    .bg-panel-login::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //@include horizontal-gradient(rgba(lighten($navy,0%),0.95), rgba($navy,0.85), 100%);
        //@include horizontal-gradient(rgba(white,1) 0, rgba(white,0.55), 100%);




        background: rgba(255,255,255,1);
        background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0.75)));
        background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 100%);
        background: -o-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 100%);
        background: -ms-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 100%);
        background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );

        border-top-right-radius: $radiusXl;
        border-bottom-right-radius: $radiusXl;
    }

    .footer{
        background: transparent;
        box-shadow: none;
        bottom: inherit;
        left: inherit;
        padding: 0;
        position: relative;
        right: inherit;
    }


}



