.nav > li > a {
    color: $nav-text-color;
    font-weight: 600;
    padding: 14px 20px 14px 25px;
    display: block;
}

.nav.metismenu > li {
    display: block;
    width: 100%;
    position: relative;
}

.nav.metismenu .dropdown-menu > li > a {
    padding: 3px 20px;
    display: block;
}

.nav.navbar-right > li > a {
    color: #999c9e;
}

.nav > li.active > a {
    color: #ffffff;
}

.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
    background-color: darken($nav-bg, 6%);
    color: white;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background: #fff;
}

.nav.navbar-top-links > li > a:hover,
.nav.navbar-top-links > li > a:focus {
    background-color: transparent;
}

.navbar-static-side .nav > li > a > i {
    margin-right: 0.5rem;
    font-size: 1.5rem;
    position: relative;
    top: 0.1rem;

}

.navbar-static-side .nav > li > a {
    color: $nav-text-color;
    font-weight: 500;
    padding: 0.5rem 15px 0.5rem 15px;
    display: block;
    font-size: 0.9rem;
    transition: all ease-in-out 0.6s;
    line-height: 1;


    > span.mdi{
        font-size: 1.4rem;
        position: absolute;
        top: 10px;
        right: 15px;
        transform: rotate(90deg);
        transition: $trasition;
    }



}

.navbar-static-side .nav > li.active > a{
    padding-left: 10px;
        > span.mdi {
            transform: rotate(0deg);
        }
}

.navbar {
    border: 0;
}

.navbar-brand {
    display: inline-block;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    line-height: inherit;
    white-space: nowrap;
    position: relative;
    top: 1rem;
}

.navbar-default {
    background-color: transparent;
    border-color: $nav-bg;
}

.body-small .navbar-top-links li:last-child {
    margin-right: 0;
}

.dropdown-menu {
    border: medium none;
    border-radius: $border-radius;
    box-shadow: 0 0 1rem -0.25rem rgba(86, 96, 117, 0.15);
    float: left;
    font-size: 0.9rem;
    left: 0;
    list-style: none outside none;
    padding: 0;
    position: absolute;
    text-shadow: none;
    top: 100%;
    z-index: 1000;
}

.dropdown-menu > li > a {
    border-radius: 3px;
    color: inherit;
    line-height: 25px;
    margin: 4px;
    text-align: left;
    font-weight: normal;
    display: block;
    padding: 3px 20px;
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
    color: #fff;
    text-decoration: none;
    background-color: $navy;
    outline: 0;
}

.dropdown-menu > li > a.font-bold {
    font-weight: 600;
}

.navbar-top-links .dropdown-menu li {
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
    padding: 0.25rem 1.2rem;
    margin: 0.5rem;
    font-size: 1rem;
    min-height: 0;
    -webkit-transition: $trasition;
    -moz-transition: $trasition;
    -ms-transition: $trasition;
    -o-transition: $trasition;
    transition: $trasition;
}

.navbar-top-links .dropdown-menu > li > a:focus,
.navbar-top-links .dropdown-menu > li > a:hover {
    color: $dark;
    text-decoration: none;
    background-color: $gray;
}

.navbar-top-links .dropdown-menu li a div {
    white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
    width: 310px;
    min-width: 0;
}

.navbar-top-links .dropdown-messages {
    margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
    margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
    margin-left: -123px;
}

.navbar-top-links .dropdown-user {
    right: 0;
    left: auto;
}

.dropdown-messages, .dropdown-alerts {
    padding: 10px 10px 10px 10px;
}

.dropdown-messages li a, .dropdown-alerts li a {
    font-size: 12px;
}

.dropdown-messages li em, .dropdown-alerts li em {
    font-size: 10px;
}

.nav.navbar-top-links .dropdown-alerts a {
    font-size: 12px;
}

.nav-header {
    background-color: $nav-bg;
    background-image: $nav-profile-pattern;
    position: relative;
    padding: 10px 15px;
    padding-left: 48px;


    .mini-navbar & {
        padding-left: 36px;
    }

}

.navbar-default .nav > li.nav-header > a.navbar-minimalize {

    position: absolute;
    top: 10px;
    left: 15px;
    height: 40px;

    > span {
        width: 12px;
        height: 3px;
        display: block;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        transition: $trasition;

        &::before {
            content: "";
            position: absolute;
            top: -6px;
            left: 0;
            width: 10px;
            height: 3px;
            display: block;
            background: #fff;
            transition: $trasition;
        }

        &::after {
            content: "";
            position: absolute;
            top: auto;
            bottom: -6px;
            left: 0;
            width: 7px;
            height: 3px;
            display: block;
            background: #fff;
            transition: $trasition;
        }
    }

    &:hover {
        background: none;

        > span {
            width: 8px;

            &::before {
                width: 4px;
            }

            &::after {
                width: 12px;
            }
        }
    }

    &:focus {
        background: none;
    }

    body.mini-navbar & > span {
        width: 16px;
        height: 4px;

        &::before {
            top: -8px;
            width: 12px;
            height: 4px;
        }

        &::after {
            bottom: -8px;
            width: 9px;
            height: 4px;
        }
    }

    body.mini-navbar &:hover {
        > span {
            width: 12px;

            &::before {
                width: 8px;
            }

            &::after {
                width: 16px;
            }
        }
    }



}




/*

.minimalize-styl-2 {
    padding: 1.4rem 1rem;
    margin: 0;
    display: block;
    font-size: 1.4rem;
    float: left;
    color: $navy;
    -webkit-transition: 0.3s all ease-in-out;
    -moz-transition: 0.3s all ease-in-out;
    -ms-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}

.minimalize-styl-2 i {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 5rem;
    line-height: 2.2rem;
    font-size: 1.4rem;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.minimalize-styl-2:hover i {
    background-color: $navy;
    color: white;
}

.minimalize-styl-2:hover {
    color: white;
    transform: scale(1.1);
}


*/

.navbar-default .nav > li.nav-header > a.navbar-brand {
    color: white;
    font-weight: 500;
    padding: 0;
    margin: 0;
    top: inherit;
    left: inherit;
    right: inherit;
    bottom: inherit;
    display: block;
    font-size: inherit;
    transition: all ease-in-out 0.6s;
    height: 40px;

    &:hover {
        background: none;
    }

    > img {
        transition: $transition;

        body.mini-navbar &.white {
            display: none;
        }

        /*&.dark {
            display: none;
            opacity: 0;
        }



        body.mini-navbar &.dark {
            display: none;
            opacity: 1;
        }*/
    }

}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.profile-element .dropdown-toggle::after {
    display: none;
}

.pace-done .nav-header {
    transition: all 0.4s;
}

ul.nav-second-level {
    background: rgba(darken($nav-bg, 3%), 0.35);
}

.nav > li.active {
    border-left: 0.3rem solid $blue;
    background: rgba(darken($nav-bg, 15%), 0.35);
}

.navbar-static-side .nav > li.active > a {

}

.nav.nav-second-level > li.active {
    border: none;
}

.nav.nav-second-level.collapse[style] {
    height: auto !important;
}

.nav-header a {
    color: #DFE4ED;
}

.nav-header .text-muted {
    color: #8095a8 !important;
}

.navbar-form-custom {
    float: left;
    height: 50px;
    padding: 0;
    width: 200px;
    display: block;
}

.navbar-form-custom .form-group {
    margin-bottom: 0;
}

.navbar-top-links li {
    display: inline-block;
    align-self: center;
}

.nav.navbar-top-links .profile-element {
    width: 200px;
    height: 40px;
}

.nav.navbar-top-links .profile-element .client-avatar {
    position: relative;
    width: 40px;
}

.nav.navbar-top-links .profile-element a {
    color: $top-nav-color-link;
}

.nav.navbar-top-links li.icon {

    > a {
        padding: 0;
        margin: 10px 1rem;
        height: 40px;
        line-height: 1;
        color: $dark-gray;
        text-align: center;
        display: block;
        transition: all ease-in-out 0.3s;
        position: relative;



        > i {
            line-height: 1;
            font-size: 2.4rem;
        }

        &:hover {
            color: darken($dark-gray, 10%);
        }
    }


    &.show{
        > a {
            color: darken($dark-gray,10%);
        }

        .dropdown-menu {
            right: 10px;
            left: auto;
            min-width: 10rem;
            padding: 1rem 0;

            > a{
                //color: darken($dark-gray,50%);
                color: $dark;
                padding: 0.5rem 1rem;
                padding-left: 2.6rem;
                position: relative;

                > .mdi{
                    position: absolute;
                    font-size: 1.2rem;
                    top: 5px;
                    left: 15px;
                }

                &:hover{
                    color: darken($dark-gray,70%);
                }
            }
        }

    }


}

.nav.navbar-top-links li.separator {
    padding: 0;
    margin: 0 1rem;

    span {
        width: 2px;
        height: 2.5rem;
        display: block;
        margin: 0 auto;
        background: $dark-gray;
    }
}

.nav.navbar-top-links li.profile {
    > a {
        padding: 10px 1rem;
        display: flex;
        > span.avatar{
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 5rem;
            margin-right: 1rem;
            position: relative;
            display: block;

            &::before{
                content: '';
                display: block;
                width: 0.75rem;
                height: 0.75rem;
                position: absolute;
                bottom: 0;
                right: 0;
                background: $green;
                border-radius: 5rem;
            }

            img{
                border-radius: 5rem;
                overflow: hidden;
            }


        }
        > span.name{
            display: block;
            position: relative;
            padding-right: 2rem;
            line-height: 1;
            margin-top: 0.3rem;
            color: $dark;
            font-weight: 700;

            > small{
                font-size: 0.75rem;
                font-weight: 500;
                display: block;
                line-height: 1;
                position: relative;
                text-align: left;
                margin-top: 0.2rem;
                color: rgba($dark,0.60);
            }

            > i{
                position: absolute;
                display: block;
                top: auto;
                right: 0;
                bottom: 0;
                font-size: 1.2rem;
                transform: rotate(90deg);
                transition: $trasition;
            }

        }
    }

    &.show > a{
        > span.name{
            > i{
                transform: rotate(0);
            }
        }
    }



}



/*
.nav.navbar-top-links li.icon a:hover{
    color: darken($nav-bg,1);
}



.nav.navbar-top-links li.icon a i {
    margin: 0;
}
*/

.point-info {
    display: block;
    position: absolute;
    top: -0.3rem;
    right: -0.2rem;
    width: 1.4rem;
    height: 1.4rem;
    line-height: 1.4rem;
    background-color: #fff;
    -webkit-box-shadow: $box-shadow;
    -moz-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
    font-size: 0.7rem;
    font-weight: 700;
    border-radius: 50%;
}

.navbar-form-custom .form-control {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    font-size: 14px;
    height: 60px;
    margin: 0;
    z-index: 2000;
}

.nav.navbar-top-links .dropdown-toggle::after {
    display: none;
}

.navbar.navbar-static-top {
    padding: 0;
    width: 100%;
    align-items: inherit;
}

.navbar-static-top .dropdown-menu {
    right: 15px;
    left: auto;
    min-width: 16rem;
}

.navbar-static-top .dropdown-menu {
    border-radius: 0.25rem;
    font-size: 0.9rem;
    box-shadow: 0 -0.5rem 1rem -0.25rem rgba(86, 96, 117, 0.15);
    padding: 0.5rem 0;



    .company {
        padding: 10px 15px;
        display: flex;

        > span.avatar{
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 0.25rem;
            margin-right: 1rem;
            position: relative;
            display: block;
            overflow: hidden;

            img{
                overflow: hidden;
                width: 100%;
            }
        }

        > span.name{
            display: block;
        }


    }




    &::before{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #fff transparent;
        position: absolute;
        top: -10px;
        right: 15px;
    }

    > a.dropdown-item{
        padding: 0.25rem 1rem;
        font-size: 0.85rem;
    }

    > h6.dropdown-header {
        display: block;
        padding: 0.5rem 1rem;
        font-weight: 700;
        margin-bottom: 0;
        font-size: 1rem;
        white-space: nowrap;
        color: $dark
    }

    > .dropdown-divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        border-top: 1px solid $dark-gray;
    }

}



.count-info .label {
    line-height: 12px;
    padding: 2px 5px;
    position: absolute;
    right: 6px;
    top: 12px;
}

.arrow {
    float: right;
}

.fa.arrow:before {
    content: "\f104";
}

.active > a > .fa.arrow:before {
    content: "\f107";
}

.nav-second-level li,
.nav-third-level li {
    border-bottom: none !important;
}

.nav.nav-third-level > li.active {
    border: none;
}

.navbar-static-side .nav .nav-second-level li a {
    padding: 0.5rem 1rem 0.5rem 1rem;
    padding-left: 2.8rem;
    font-size: 0.9rem;
    opacity: 0.85;
    .mini-navbar &{
        padding-left: 1rem;
    }
}


.navbar-static-side .nav .nav-second-level li div.separator {
    margin: 0 1rem 0 1rem;
    height: 1px;
    display: block;
    background-color: $nav-bg;
}

.fixed-sidebar.mini-navbar .nav-second-level.collapsing li a, .nav-second-level.collapsing li a {
    min-width: $sidebar-width;
}

.body-small .nav-second-level.collapsing li a, .mini-navbar .nav-second-level.collapsing li a {
    min-width: 140px;
}

.nav-third-level li a, .fixed-sidebar.mini-navbar .nav-second-level li .nav-third-level li a {
    padding-left: 62px;
}

.nav-second-level li:last-child {
    padding-bottom: 10px;
}

body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav li:hover > .nav-second-level,
.mini-navbar .nav li:focus > .nav-second-level {
    display: block;
    border-radius: 0 $border-radius $border-radius 0;
    min-width: 240px;
    height: auto;
}

body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 3px;

    &:hover{
        background-color: $navy;
    }
}

.fixed-nav .slimScrollDiv #side-menu {
    padding-bottom: 60px;
}

.mini-navbar .nav-second-level li a {
    padding: 1rem;
}

.mini-navbar .nav .nav-second-level {
    position: absolute;
    left: 70px;
    top: 0;
    background-color: darken($nav-bg, 6%);
    padding: 1rem;
    font-size: 0.9rem;
}

.canvas-menu.mini-navbar .nav-second-level {
    background: darken($nav-bg, 3%);
}

.mini-navbar li.active .nav-second-level {
    left: 50px
}

.navbar-default .special_link a {
    background: $navy;
    color: white;
}

.navbar-default .special_link a:hover {
    background: #17987e !important;
    color: white;
}

.navbar-default .special_link a span.label {
    background: #fff;
    color: $navy;
}

.navbar-default .landing_link a {
    background: lighten($navy, 3%);
    color: white;
}

.navbar-default .landing_link a:hover {
    background: $navy !important;
    color: white;
}

.navbar-default .landing_link a span.label {
    background: #fff;
    color: lighten($navy, 3%);
}

.logo-element {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: white;
    display: none;
    padding: 18px 0;
}

.navbar-static-side {
    transition: width 0s;
}

.footer {
    transition: margin 0s;
}

.pace-done .navbar-static-side, .pace-done .nav-header, .pace-done li.active, .pace-done #page-wrapper, .pace-done .footer {
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}

.navbar-fixed-top {
    background: #fff;
    transition-duration: 0.4s;
    border-bottom: 1px solid #e7eaec !important;
    z-index: 2030;
    position: fixed;
    right: 0;
    left: 0;
    padding: 0;
    top: 0
}

.navbar-fixed-top .navbar-form-custom .form-control {
    height: 50px
}

.navbar-fixed-top, .navbar-static-top {
    /*-webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
    background-color: white;

    */
}

.fixed-nav #wrapper {
    margin-top: 0;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none;
    border-color: #DDDDDD #DDDDDD rgba(0, 0, 0, 0);
    border-bottom: $gray;
    border-image: none;
    border-style: solid;
    border-width: 1px;
    color: #555555;
    cursor: default;
}

.nav.nav-tabs li {
    background: none;
    border: none;
}

body.fixed-nav #wrapper .navbar-static-side,
body.fixed-nav #wrapper #page-wrapper {
    margin-top: 60px;
}

body.top-navigation.fixed-nav #wrapper #page-wrapper {
    margin-top: 0;
}

body.fixed-nav.fixed-nav-basic .navbar-fixed-top {
    left: $sidebar-width;
}

body.fixed-nav.fixed-nav-basic.mini-navbar .navbar-fixed-top {
    left: 70px;
}

body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar .navbar-fixed-top {
    left: 0;
}

body.fixed-nav.fixed-nav-basic #wrapper .navbar-static-side {
    margin-top: 0;
}

body.fixed-nav.fixed-nav-basic.body-small .navbar-fixed-top {
    left: 0;
}

body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar.body-small .navbar-fixed-top {
    left: $sidebar-width;
}

.fixed-nav .minimalize-styl-2 {
    margin: 10px 5px 5px 15px;
}

.body-small .navbar-fixed-top {
    margin-left: 0;
}

body.mini-navbar .navbar-static-side {
    width: 55px;
}

body.mini-navbar .profile-element, body.mini-navbar .nav-label, body.mini-navbar .navbar-default .nav li a span {
    display: none;
}

body.canvas-menu .profile-element {
    display: block;
}

body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav-second-level {
    display: none;
}

body.mini-navbar .navbar-default .nav > li > a {
    font-size: 16px;
}

body.mini-navbar .logo-element {
    display: block;
}

body.canvas-menu .logo-element {
    display: none;
}

body.mini-navbar .nav-header {

}

body.canvas-menu .nav-header {
    padding: 33px 25px;
}

body.canvas-menu .sidebar-collapse li {
    width: 100%;
}

body.mini-navbar #page-wrapper {
    width: calc(100% - 55px);
}

body.canvas-menu.mini-navbar #page-wrapper,
body.canvas-menu.mini-navbar .footer {
    margin: 0;
    width: 100%;
}

body.fixed-sidebar .navbar-static-side,
body.canvas-menu .navbar-static-side {
    width: $sidebar-width;
    z-index: 2001;
    height: 100vh;
    position: fixed;
}

body.fixed-sidebar.mini-navbar .navbar-static-side {
    width: 0;
}

body.fixed-sidebar #page-wrapper {
    margin: 0 0 0 $sidebar-width;
}

body.fixed-sidebar.body-small #page-wrapper {
    margin: 0;
}

body.fixed-sidebar.mini-navbar #page-wrapper {
    margin: 0 0 0 0;
    width: 100%;
}

body.body-small.fixed-sidebar.mini-navbar #page-wrapper {
    margin: 0 0 0 $sidebar-width;
}

body.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
    width: $sidebar-width;
}

.fixed-sidebar.mini-navbar .nav li:focus > .nav-second-level,
.canvas-menu.mini-navbar .nav li:focus > .nav-second-level {
    display: block;
    height: auto;
}

body.fixed-sidebar.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
    font-size: 12px;
    border-radius: 3px;
}

body.canvas-menu.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
    font-size: 13px;
    border-radius: 3px;
}

.fixed-sidebar.mini-navbar .nav-second-level li a,
.canvas-menu.mini-navbar .nav-second-level li a {
    padding: 10px 10px 10px 15px;
}

.fixed-sidebar.mini-navbar .nav-second-level,
.canvas-menu.mini-navbar .nav-second-level {
    position: relative;
    padding: 0;
    font-size: 13px;
}

.fixed-sidebar.mini-navbar li.active .nav-second-level,
.canvas-menu.mini-navbar li.active .nav-second-level {
    left: 0
}

body.fixed-sidebar.mini-navbar .navbar-default .nav > li > a,
body.canvas-menu.mini-navbar .navbar-default .nav > li > a {
    font-size: 13px;
}

body.fixed-sidebar.mini-navbar .nav-label,
body.fixed-sidebar.mini-navbar .navbar-default .nav li a span,
body.canvas-menu.mini-navbar .nav-label,
body.canvas-menu.mini-navbar .navbar-default .nav li a span {
    display: inline;
}

body.canvas-menu.mini-navbar .navbar-default .nav li .profile-element a span {
    display: block;
}

.canvas-menu.mini-navbar .nav-second-level li a,
.fixed-sidebar.mini-navbar .nav-second-level li a {
    padding: 7px 10px 7px 52px
}

.fixed-sidebar.mini-navbar .nav-second-level,
.canvas-menu.mini-navbar .nav-second-level {
    left: 0
}

body.canvas-menu nav.navbar-static-side {
    z-index: 2001;
    background: $nav-bg;
    height: 100%;
    position: fixed;
    display: none;
}

body.canvas-menu.mini-navbar nav.navbar-static-side {
    display: block;
    width: $sidebar-width;
}

.navbar-login {
    padding: 1.5rem 0;
}

.navbar-login .navbar-nav .nav-item .nav-link {
    padding: 0;
}

.navbar-login .navbar-nav .nav-item .nav-link .mdi {
    font-size: 1.4rem;
}
