/* Only demo */
@media (max-width: 1000px) {
    .welcome-message {
        display: none;
    }
}

input{
    filter: none;
}


.modal-xl-100vw{
    max-width: 100vw;
}

.modal-xl-90vw{
    max-width: 90vw;
}

.modal-xl-80vw{
    max-width: 80vw;
}

.modal-xl-70vw{
    max-width: 70vw;
}

.modal-xl-60vw{
    max-width: 60vw;
}

.w-60{
    width: 60%;
}

.w-65{
    width: 65%;
}

.w-70{
    width: 70%;
}

.w-80{
    width: 80%;
}

.w-85{
    width: 85%;
}

.w-90{
    width: 90%;
}

.w-95{
    width: 95%;
}


@for $i from 1 through 5 {
    .letter-space-#{$i}{
        letter-spacing: $i * 0.1rem;
    }
}




.border-radius-xl{
    border-radius: $radiusXl;
}

.border-radius-right-xl{
    border-top-right-radius: $radiusXl;
    border-bottom-right-radius: $radiusXl;
}

.border-radius-left-xl{
    border-top-left-radius: $radiusXl;
    border-bottom-left-radius: $radiusXl;
}


.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    z-index: 9999999 !important;
}

.loader-backdrop {
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.65);
    z-index:999999;
}





#jstree ul li > ul {
    margin: 0 -16px;

}



#jstree ul li.list-group-item-root li {
    border-radius: 0;
    padding-bottom: 0;
    margin-bottom: -1px;
}

.list-group-item-root {
    padding-bottom: 0;
}

#jstree ul li {
    position:relative
}


#jstree ul li .btn {
    position: absolute;
    top: 5px;
    right: 15px;
}

#jstree ul li span {
    display:inline-block;
    margin-bottom: 10px;
    font-weight: 500;
}

#jstree ul > li > ul > li > span {
    display: inline-block;
    padding-left: 30px;
}

#jstree ul > li > ul > li  span{
    display: inline-block;
    position: relative;
}

#jstree ul > li > ul > li  span::before{
    font-family: "Material Design Icons";
    content: '\F60D';
    font-size: 0.75rem;
    color: rgba($dark, 0.75);
    display: inline-block;
    margin-right: 0.5rem;


}

#jstree ul > li > ul > li > ul > li > span {
    display: inline-block;
    padding-left: 45px;
}

#jstree ul > li > ul > li > ul > li > ul > li > span {
    display: inline-block;
    padding-left: 60px;
}

#jstree ul > li > ul > li > ul > li > ul > li > ul > li > span {
    display: inline-block;
    padding-left: 75px;
}

#jstree ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > span {
    display: inline-block;
    padding-left: 90px;
}

#jstree ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > span {
    display: inline-block;
    padding-left: 105px;
}

#jstree ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li >  ul > li > span {
    display: inline-block;
    padding-left: 120px;
}

#jstree ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > > ul > li >  ul > li > span {
    display: inline-block;
    padding-left: 135px;
}

#jstree ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > > ul > li > ul > li > ul > li > span {
    display: inline-block;
    padding-left: 150px;
}


.dashboard {

    .card {

        h1.number{
            font-size: 2.4rem;
            line-height: 1;
            letter-spacing: -0.15rem;
            font-weight: 800;
        }
    }
}





.bg-gradient-directional {
    /*
    background-image: -webkit-linear-gradient(45deg,#FF394F,#FF8090);
    background-image: -moz- oldlinear-gradient(45deg,#FF394F,#FF8090);
    background-image: -o-linear-gradient(45deg,#FF394F,#FF8090);
    background-image: linear-gradient(45deg,#FF394F,#FF8090);
    background-repeat: repeat-x;
    */


    background: -moz-linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 60%, rgba(255,255,255,0.1) 100%);
    //background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(60%, rgba(255,255,255,0.1)), color-stop(100%, rgba(255,255,255,0.1)));
    background: -webkit-linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 60%, rgba(255,255,255,0.1) 100%);
    background: -o-linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 60%, rgba(255,255,255,0.1) 100%);
    background: -ms-linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 60%, rgba(255,255,255,0.1) 100%);
    background: linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 60%, rgba(255,255,255,0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );


    mix-blend-mode: soft-light;
}



@function url-friendly-colour($colour) {
    @return '%23' + str-slice('#{$colour}', 2, -1)
}


.bg-primary{
    .bg-hexagons {
        background-image: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'28\' height=\'49\' viewBox=\'0 0 28 49\'%3E%3Cg fill-rule=\'evenodd\'%3E%3Cg id=\'hexagons\' fill=\'#{url-friendly-colour($navy)}\' fill-opacity=\'1\' fill-rule=\'nonzero\'%3E%3Cpath d=\'M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
    }

}



.pull-up {
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    -moz-transition: all .25s ease;
    transition: all .25s ease;
}


.pull-up:hover {
    -webkit-transform: translateY(-4px) scale(1.02);
    -moz-transform: translateY(-4px) scale(1.02);
    -ms-transform: translateY(-4px) scale(1.02);
    -o-transform: translateY(-4px) scale(1.02);
    transform: translateY(-4px) scale(1.02);
    -webkit-box-shadow: 0 14px 24px rgba(62,57,107,.2);
    box-shadow: 0 14px 24px rgba(62,57,107,.2);
    z-index: 999;
}
