.top-navigation #page-wrapper {
    width: 100%;
}

.top-navigation .navbar-nav .dropdown-menu > .active > a {
    background: white;
    color: $navy;
    font-weight: bold;
}

.white-bg .navbar-fixed-top, .white-bg .navbar-static-top {
    background: #fff;
}

.top-navigation .navbar {
    margin-bottom: 0;
}

.top-navigation .nav > li > a {
    padding: 15px 20px;
    color: #676a6c;
}

.top-navigation .nav > li a:hover, .top-navigation .nav > li a:focus {
    background: #fff;
    color: $navy;
}

.top-navigation .navbar .nav > li.active {
    background: #fff;
    border: none;
}

.top-navigation .nav > li.active > a {
    color: $navy;
}

.top-navigation .navbar-right {
    margin-right: 10px;
}

.top-navigation .navbar-nav .dropdown-menu {
    box-shadow: none;
    border: 1px solid #e7eaec;
}

.top-navigation .dropdown-menu > li > a {
    margin: 0;
    padding: 7px 20px;
}

.navbar .dropdown-menu {
    margin-top: 0;
}

.top-navigation .navbar-brand {
    background: $navy;
    color: #fff;
    padding: 15px 25px;
    font-size: 18px;
    line-height: 20px;
    //margin: 8px;
}

.top-navigation .navbar-top-links li:last-child {
    margin-right: 0;
}

.top-navigation.mini-navbar #page-wrapper,
.top-navigation.body-small.fixed-sidebar.mini-navbar #page-wrapper,
.mini-navbar .top-navigation #page-wrapper,
.body-small.fixed-sidebar.mini-navbar .top-navigation #page-wrapper,
.canvas-menu #page-wrapper {
    margin: 0;
    width: 100%;
}

.top-navigation.fixed-nav #wrapper, .fixed-nav #wrapper.top-navigation {
    margin-top: 50px;
}

.top-navigation .footer.fixed {
    margin-left: 0 !important;
}

.top-navigation .wrapper.wrapper-content {
    padding: 40px;
}

.top-navigation.body-small .wrapper.wrapper-content, .body-small .top-navigation .wrapper.wrapper-content {
    padding: 40px 0 40px 0;
}

.navbar-toggler {
    background-color: $navy;
    color: #fff;
    padding: 6px 12px;
    font-size: 14px;
    margin: 8px;
}

.top-navigation .navbar-nav .open .dropdown-menu > li > a, .top-navigation .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 10px 15px 10px 20px;
}


.navbar-static-top {

    .navbar-header{
        padding: 10px 15px;
        display: block;
        box-sizing: border-box;
        line-height: 1;
        margin: 0;
        height: 60px;
        max-width: 200px;
        display: none;

        .navbar-brand{
            top:0;
            position: relative;

            > img{
                &.dark {
                    display: none;
                    opacity: 0;
                    transition: $trasition;
                }

                body.mini-navbar &.dark {
                    display: block;
                    opacity: 1;
                }
            }

        }

        body.mini-navbar &{
            display: block;
        }


    }
}




.nav.navbar-top-links.navbar-left{
    max-width: 380px;

    li.company a {
        padding: 10px 15px;
        display: flex;

        > span.avatar{
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 0.25rem;
            margin-right: 1rem;
            position: relative;
            display: block;
            overflow: hidden;
            background: $light-gray;

            img{
                overflow: hidden;
                width: 100%;
            }


        }

        > span.name{
            width: calc(100% - 3.5rem);
            display: block;
            position: relative;
            line-height: 1;
            margin-top: 0.3rem;
            color: $dark;
            font-weight: 700;
            text-align: left;

            > span{
                display: block;
                line-height: 1.2rem;
                height: 1.2rem;
                overflow: hidden;
            }

            > small{
                font-size: 0.75rem;
                font-weight: 500;
                display: block;
                line-height: 1;
                position: relative;
                text-align: left;
                margin-top: 0.2rem;
                color: rgba($dark,0.60);
            }

            > i{
                position: absolute;
                display: block;
                top: auto;
                right: 0;
                bottom: 0;
                font-size: 1.2rem;
            }

        }


    }


}









@media (max-width: 768px) {
    .top-navigation .navbar-header {
        display: block;
        float: none;
    }
}

.menu-visible-lg, .menu-visible-md {
    display: none !important;
}

@media (min-width: 1200px) {
    .menu-visible-lg {
        display: block !important;
    }
}

@media (min-width: 992px) {
    .menu-visible-md {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .menu-visible-md {
        display: block !important;
    }

    .menu-visible-lg {
        display: block !important;
    }
}
